import { Fragment, useEffect, useState } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import MUIList from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Spinner from '@mui/material/CircularProgress';
import { SxProps } from '@mui/system';
import { mainCategories } from 'client/common/constants';
import capitalize from '@mui/utils/capitalize';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

export interface ListItem {
    label: string;
    navigate?: (event: any) => void;
    subItemsLoading?: boolean;
    subItemsFailure?: boolean;
    fetchSubItems?: () => {
        payload: mainCategories;
        type: string;
    };
    subItemsBackground?: string;
    subItemsPosition?: 'absolute';
    subItemsTop?: string;
    subItemsLeft?: string;
    subItemsPaddingLeft?: number;
    icon?: JSX.Element;
    items?: ListItem[];
}

export interface ListProps {
    items: ListItem[];
    subHeader?: string;
    component?: 'nav' | 'div';
    disablePadding?: boolean;
    sxProps?: SxProps;
    row?: boolean;
    background?: string;
    width?: string;
    singleExpansion?: boolean;
    subItemsCollapseOnClick?: boolean;
    expansionClickCallback?: () => void;
    level?: number;
}

interface ListButtonProps {
    item: ListItem;
    sxProps?: SxProps;
    expandableSectionOpen?: boolean;
    expansionClickCallback?: (label: string) => void;
    collapseOnClick?: boolean;
    level?: number;
    keyPrefix?: string;
}

interface ExpandedSectionProps {
    open: boolean;
    items: ListItem[];
    loading?: boolean;
    background?: string;
    position?: 'absolute';
    top?: string;
    left?: string;
    paddingLeft?: number;
    collapseOnClick?: boolean;
    expansionClickCallback?: () => void;
    subItemsBackgroundColor?: string;
    level?: number;
    keyPrefix?: string;
}

const Icon = ({ icon, keyPrefix }: { icon: JSX.Element, keyPrefix?: string }): JSX.Element => {
    return (
        <ListItemIcon key={`${keyPrefix}list_button_item_icon`}>
            {icon}
        </ListItemIcon>
    );
};

function Expansion({ open, keyPrefix }: { open: boolean, keyPrefix?: string }): JSX.Element {
    return open ? <ExpandLess key={`${keyPrefix}expansion_less`} /> : <ExpandMore key={`${keyPrefix}expansion_more`} />
}

function ExpandedSection({
    open,
    items,
    loading,
    background,
    position,
    top,
    left,
    paddingLeft,
    collapseOnClick,
    expansionClickCallback,
    subItemsBackgroundColor,
    level,
    keyPrefix
}: ExpandedSectionProps): JSX.Element {
    return (
        <Collapse
            key={`${keyPrefix}list_button_expanded_collapse`}
            in={open}
            timeout="auto"
            sx={{ position, top, left }}
            unmountOnExit
        >
            {loading ? <Box
                bgcolor={subItemsBackgroundColor}
                display='flex'
                alignItems='center'
                padding={`0 ${String(((level ?? 0) + 1) * 16)}px`}
                height='48px'
                key={`${keyPrefix}list_button_expanded_box`}
            >
                <Spinner key={`${keyPrefix}list_button_spinner`} size='30px' />
            </Box> : <List
                items={items}
                component="div"
                disablePadding
                sxProps={{ pl: paddingLeft ?? ((level ?? 0) + 1) * 4 }}
                background={background}
                subItemsCollapseOnClick={collapseOnClick}
                expansionClickCallback={expansionClickCallback}
                level={(level ?? 0) + 1}
                key={`${keyPrefix}list_button_expanded_list`}
            />}
        </Collapse>
    )
}

function ListButton({
    item,
    sxProps,
    expandableSectionOpen,
    expansionClickCallback,
    collapseOnClick,
    level,
    keyPrefix
}: ListButtonProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const [id, setId] = useState('');

    const handleExpansionClick = () => {
        if (item.fetchSubItems && !item.items?.length && !open) {
            item.fetchSubItems();
        }

        if (expansionClickCallback && !open) {
            expansionClickCallback(item.label);
        }

        setOpen(!open);
    }

    const handleClick = (e: any) => {
        if (collapseOnClick && expansionClickCallback) {
            expansionClickCallback(item.label);
        }

        if (item.navigate) {
            item.navigate(e);
        }
    }

    useEffect(() => {
        if (expandableSectionOpen !== undefined) {
            setOpen(expandableSectionOpen);
        }
    }, [expandableSectionOpen])

    useEffect(() => {
        setId(item.label.replace(/\s/g, '_').toLowerCase());
    }, [item])

    const handleGenericClick = (e: MouseEvent) => {
        const header = document.querySelectorAll('header')[0];
        const home = document.querySelector('[data-id=home]');

        if ((!header?.contains(e.target as Element | null) || home?.contains(e.target as Element | null)) && (e.target as Element).tagName !== 'svg' && (e.target as Element).tagName !== 'path') {
            setOpen(false);
        }
    }

    useEffect(() => {
        if (collapseOnClick) {
            window.addEventListener('click', handleGenericClick);
        }

        return () => {
            window.removeEventListener('click', handleGenericClick);
        };
    }, [collapseOnClick])

    return (
        <Box
            display='flex'
            flexDirection='column'
            position='relative'
            key={`${keyPrefix}list_button_box`}
        >
            <ListItemButton
                onClick={item.items ? handleExpansionClick : handleClick}
                data-id={id}
                sx={sxProps}
                key={`${keyPrefix}list_item_button`}
            >
                {item.icon ? <Icon key={`${keyPrefix}list_button_icon`} icon={item.icon} keyPrefix={keyPrefix} /> : null}
                <ListItemText key={`${keyPrefix}list_button_text`} primary={capitalize(item.label.replace(/_/g, ' '))} />
                {item.items ? <Expansion key={`${keyPrefix}list_button_expansion`} keyPrefix={keyPrefix} open={open} /> : null}
            </ListItemButton>
            {item.items ? <ExpandedSection
                key={`${keyPrefix}list_button_expanded_section`}
                open={open}
                items={item.items}
                loading={item.subItemsLoading}
                background={item.subItemsBackground}
                position={item.subItemsPosition}
                top={item.subItemsTop}
                left={item.subItemsLeft}
                paddingLeft={item.subItemsPaddingLeft}
                collapseOnClick={collapseOnClick}
                expansionClickCallback={collapseOnClick ? handleExpansionClick : undefined}
                subItemsBackgroundColor={item.subItemsBackground}
                level={level}
                keyPrefix={keyPrefix}
            /> : null}
        </Box>
    );
};

const SubHeader = ({ subHeader, level }: { subHeader: string, level?: number }): JSX.Element => {
    return (
        <ListSubheader key={`${level ?? 0}_sub_header_list`} component="div" id={`list-subheader-${subHeader.replace(/\s/g, '_').toLowerCase()}`}>
            {subHeader}
        </ListSubheader>
    );
};

function List({
    items,
    subHeader,
    component,
    disablePadding,
    sxProps,
    row,
    background,
    width,
    singleExpansion,
    subItemsCollapseOnClick,
    expansionClickCallback,
    level
}: ListProps) {
    const [expandedSection, setExpandedSection] = useState(undefined as string | undefined);

    const expansionClickHandler = (label: string) => {
        if (expansionClickCallback) {
            return expansionClickCallback();
        } else if (singleExpansion) {
            return setExpandedSection(label);
        }
    }

    
    return (
        <MUIList
            sx={{ width: width ?? '100%', bgcolor: background ?? 'background.paper', display: 'flex', flexDirection: row ? 'row' : 'column' }}
            component={component ?? 'nav'}
            aria-labelledby={subHeader ? `list-subheader-${subHeader?.replace(/\s/g, '_').toLowerCase()}` : undefined}
            subheader={subHeader ? (
                <Fragment>
                    <Divider key={`${level ?? 0}_sub_header_divider`} />
                    <SubHeader key={`${level ?? 0}_sub_header`} subHeader={subHeader} level={level} />
                </Fragment>
            ) : undefined}
            disablePadding={disablePadding}
            key={`${level ?? 0}_list`}
        >
            {items.map((item, i) => {
                const keyPrefix = `${item.label.replace(/\s/g, '_').toLowerCase()}_${level}_`;
                return (
                    <Fragment>
                        <Divider key={`${keyPrefix}divider`} />
                        <ListButton
                            item={item}
                            sxProps={sxProps}
                            expandableSectionOpen={singleExpansion && expandedSection ? expandedSection === item.label : undefined}
                            expansionClickCallback={expansionClickCallback || singleExpansion ? expansionClickHandler : undefined}
                            collapseOnClick={subItemsCollapseOnClick}
                            key={`${keyPrefix}list_button`}
                            keyPrefix={keyPrefix}
                            level={level}
                        />
                        {i === items.length - 1 && item.items?.length ? <Divider key={`${keyPrefix}list_button_divider`} /> : null}
                    </Fragment>
                );
            })}
        </MUIList>
    );
}

export default List;
