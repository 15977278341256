import { Link } from "react-router-dom";

import Article from "../article";
import styles from './index.module.scss';

const ArticlePreview = ({ article, hideReadMore, hideHeader }: { article: any, hideReadMore?: boolean, hideHeader?: boolean }) => {
    const uri = `/${article.mainCategory}/${article.secondaryCategory}/${article.id}`;

    return (
        <div className={styles.previewWrapper}>
            <Article
                article={{ ...article, secondaryCategory: undefined }}
                maxChars={300}
                linkHeaderTo={uri}
                hideHeader={hideHeader}
                hideFooter
                ignoreDropCap
            />
            {hideReadMore ? null : <div className={styles.readMore}>
                <Link to={uri}>Read more</Link>
            </div>}
        </div>
    );
}

export default ArticlePreview;
