import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '@mui/material/CircularProgress';
import { authorMap, BATCH_GET_PATH } from 'client/common/constants';
import makeRequest from 'client/common/request';
import ArticlePreview from 'client/components/articlePreview';

import styles from './index.module.scss';

const HomePage = () => {
    const { mainCategory, secondaryCategory } = useParams() as Record<string, string>;
    const [loading, setLoading] = useState(true);
    const [hideReadMore, setHideReadMore] = useState(false);
    const [hideHeader, setHideHeader] = useState(false);
    const [articles, setArticles] = useState([]);
    const category = secondaryCategory ?? mainCategory;
    const title = authorMap[category] ?? category.replace(/_/g, ' ');

    useEffect(() => {
        setLoading(true);
        makeRequest(`${BATCH_GET_PATH}?mainCategory=${mainCategory}&secondaryCategory=${secondaryCategory}`, 'GET').then((res) => {
            const articlesToSet = res.Items.length ? res.Items : [{
                content: [{
                    insert: 'There are no posts for this category yet! Feel free to stop by again later to see if there are any updates!'
                }]
            }];
            setArticles(articlesToSet);
            setHideHeader(!res.Items.length);
            setHideReadMore(!res.Items.length);
            setLoading(false);
        });
    }, [mainCategory, secondaryCategory])

    return (
        <Fragment>
            <div className={styles.hero} />
            <div className={styles.content}>
                <h2 className={styles.categoryLabel}>Category: <span className={styles.titleCase}>{title}</span></h2>
                {loading ? <Spinner /> : articles.map((article) => (
                    <ArticlePreview article={article} hideHeader={hideHeader} hideReadMore={hideReadMore} />
                ))}
            </div>
        </Fragment>
    )
}

export default HomePage;
