import { useParams } from 'react-router-dom';
import Article from 'client/components/article';

import styles from './index.module.scss';

const ArticlePage = () => {
    const { articleId } = useParams() as { articleId: string };

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <Article articleId={articleId} />
            </div>
        </div>
    )
}

export default ArticlePage;
