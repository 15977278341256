import {
    Routes,
    Route
} from "react-router-dom";
import LandingPage from 'client/pages/landingPage';
import CategoryPage from 'client/pages/category';
import ArticlePage from 'client/pages/article';
import EditorPage from "client/pages/editor";

const RouterOutlet = () => {
    return (
        <Routes>
            <Route path="/new" element={<EditorPage />} />
            <Route path="/edit/:articleId" element={<EditorPage />} />
            <Route path="/:mainCategory/:secondaryCategory/:articleId" element={<ArticlePage />} />
            <Route path="/:mainCategory/home" element={<LandingPage />} />
            <Route path="/:mainCategory/:secondaryCategory" element={<CategoryPage />} />
            <Route path="/:mainCategory" element={<LandingPage />} />
            <Route path="/" element={<LandingPage />} />
        </Routes>
    )
}

export default RouterOutlet;
