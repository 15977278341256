import { Fragment, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { landingPage } from 'client/common/constants';

import LandingPageTitle from './landingPageTitle';
import LandingPageContent from './landingPageContent';
import styles from './index.module.scss';

const LandingPage = () => {
    const [config, setConfig] = useState({} as { title: string, image: string, subtitle?: string });
    const [category, setCategory] = useState('');
    const params = useParams() as Record<string, string>;
    const landingPageEl: MutableRefObject<HTMLDivElement | null> = useRef(null);

    useEffect(() => {
        if (!params?.mainCategory) {
            setConfig(landingPage.home);
            setCategory('home');
        } else {
            setConfig(landingPage[params?.mainCategory]);
            setCategory(params?.mainCategory);
        }
    }, [params?.mainCategory]);

    useEffect(() => {
        if (landingPageEl?.current && config.image) {
            landingPageEl.current.setAttribute('style', `background-image: url(${config.image})`);
        }
    }, [config.image])

    return (
        <Fragment>
            <div ref={landingPageEl} className={styles.landingPage} />
            {config.title ? <LandingPageTitle title={config.title} subtitle={config.subtitle} /> : null}
            {category ? <LandingPageContent category={category} /> : null}
        </Fragment>
    )
}

export default LandingPage;
