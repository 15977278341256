import { MouseEventHandler, useCallback, useState } from 'react';
import MUIAppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppDispatch } from 'client/hooks';
import { toggleDrawer } from 'client/store/app';
import { pageTitle } from 'client/common/constants';

import style from './index.module.scss';

const AppBar = () => {
    const dispatch = useAppDispatch();
    const toggleDrawerCallback = useCallback(
      () => dispatch(toggleDrawer()),
      [dispatch]
    );
    const [anchorElNav, setAnchorElNav] = useState(null as HTMLAnchorElement | null);
    const [anchorElUser, setAnchorElUser] = useState(null as HTMLAnchorElement | null);
  
    const handleOpenUserMenu: MouseEventHandler<HTMLAnchorElement> = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const settings: string[] = ['test', 'test2'];
  
    return (
        <MUIAppBar sx={{ backgroundColor: '#000033' }}>
            <Container maxWidth="xl" sx={{ zIndex: 999 }}>
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={toggleDrawerCallback}
                            color="inherit"
                            href=''
                        >
                            <MenuIcon />
                        </IconButton>
                        <h1 className={style.headerText}>{pageTitle}</h1>
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} href=''>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" sx={{ height: '30px', width: '30px' }} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </MUIAppBar>
    );
};

export default AppBar;
