import { call, CallEffect, put, PutEffect, select, SelectEffect } from '@redux-saga/core/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mainCategories } from 'client/common/constants';
import makeRequest from 'client/common/request';
import { CategoryItem, GetCategoriesResult } from 'models/getCategories';
import type { RootState } from '../store';

type CategoriesBoolean = Record<mainCategories, boolean>;
type Categories = Partial<Record<mainCategories, CategoryItem[]>>;

interface CategoryState {
    categoriesLoading: CategoriesBoolean;
    categories: Categories;
    categoriesFailed: CategoriesBoolean;
}

const initialState: CategoryState = {
    categoriesLoading: Object.values(mainCategories).reduce((acc, cat) => {
        acc[cat] = false;
        return acc;
    }, {} as CategoriesBoolean),
    categoriesFailed: Object.values(mainCategories).reduce((acc, cat) => {
        acc[cat] = false;
        return acc;
    }, {} as CategoriesBoolean),
    categories: Object.values(mainCategories).reduce((acc, cat) => {
        acc[cat] = [];
        return acc;
    }, {} as Categories)
}

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        fetchCategories: (state, action: PayloadAction<mainCategories>) => {
            return {
                ...state,
                categoriesLoading: {
                    ...state.categoriesLoading,
                    [action.payload]: !state.categories[action.payload]?.length
                },
                categoriesFailed: {
                    ...state.categoriesFailed,
                    [action.payload]: false
                }
            };
        },
        fetchCategoriesSuccess: (state, action: PayloadAction<{ categories: Categories, mainCategory: mainCategories }>) => {
            return {
                ...state,
                categoriesLoading: {
                    ...state.categoriesLoading,
                    ...{
                        [action.payload.mainCategory]: false
                    }
                },
                categories: {
                    ...state.categories,
                    ...action.payload.categories
                }
            };
        },
        fetchCategoriesFailure: (state, action: PayloadAction<mainCategories>) => {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [action.payload]: []
                },
                categoriesLoading: {
                    ...state.categoriesLoading,
                    [action.payload]: false
                },
                categoriesFailed: {
                    ...state.categoriesFailed,
                    [action.payload]: false
                }
            }
        }
    }
});

export const { fetchCategories, fetchCategoriesSuccess, fetchCategoriesFailure } = categoriesSlice.actions;

export const selectCategories = (state: RootState) => state.categories.categories;
export const selectCategoriesLoading = (state: RootState) => state.categories.categoriesLoading;
export const selectCategoriesFailure = (state: RootState) => state.categories.categoriesFailed;

export function* fetchCategoriesSaga(action: PayloadAction<mainCategories>): Generator<SelectEffect | CallEffect<any> | PutEffect<any>> {
    const categories = ((yield select(selectCategories)) as Categories)[action.payload];
    if (!categories?.length) {
        try {
            const categoriesRes = (yield call(makeRequest, `category?mainCategory=${action.payload}`, 'GET')) as GetCategoriesResult;
            const categories: CategoryItem[] = categoriesRes.Items;
            yield put(fetchCategoriesSuccess({
                categories: {
                    [action.payload]: categories
                },
                mainCategory: action.payload
            }));
        } catch(error) {
            yield put({
                type: 'categories/fetchCategoriesFailure',
                payload: action.payload
            });
        }
    }
}

export default categoriesSlice.reducer;
