import ArticleTitle from './articleTitle';
import ArticleAuthor from './articleAuthor';
import ArticleDate from './articleDate';
import ArticleCategory from "./articleCategory";
import styles from './index.module.scss';

const ArticleHeader = ({ article, linkTo }: { article: any, linkTo?: string }) => {
    return (
        <div className={styles.articleHeader}>
            <div className={styles.articleDetails}>
                {article.secondaryCategory ? <ArticleCategory category={article.secondaryCategory} /> : null}
            </div>
            <ArticleTitle linkTo={linkTo} title={article.title} subtitle={article.subTitle} />
            <div className={styles.articleDetails}>
                <div className={styles.articleAuthorDate}>
                    {article.author ? <ArticleAuthor author={article.author} /> : null}
                    {article.createdDate ? <ArticleDate date={article.createdDate} /> : null}
                </div>
            </div>
        </div>
    )
};

export default ArticleHeader;
