import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface AppState {
  drawerOpen: boolean;
}

const initialState: AppState = {
    drawerOpen: false
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setDrawerOpen: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                drawerOpen: action.payload
            };
        },
        toggleDrawer: (state) => {
            return {
                ...state,
                drawerOpen: !state.drawerOpen
            };
        }
    }
});

export const { toggleDrawer, setDrawerOpen } = appSlice.actions;

export const selectDrawerOpen = (state: RootState) => state.app.drawerOpen;

export default appSlice.reducer;
