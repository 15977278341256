import { useEffect, useState } from "react";
import Quill from 'quill';
import Delta from 'quill-delta';
import type Op from 'quill-delta/dist/Op';

import styles from './index.module.scss';

abstract class EditorImplementation {
    private static countState = 0;

    public static get count() {
        EditorImplementation.countState++;
        return EditorImplementation.countState;
    }
}

const ArticleContent = ({ content, ignoreDropCap, maxChars }: { content: Op[], ignoreDropCap?: boolean, maxChars?: number }) => {
    const [id, setId] = useState('');

    useEffect(() => {
        setId(`editor-${EditorImplementation.count}-${Math.floor(Math.random() * 256)}`);
    }, [])

    useEffect(() => {
        if (id && content) {
            const Embed = Quill.import('blots/block/embed');

            class Hr extends Embed {
                static create(value: any) {
                    let node = super.create(value);
                    node.setAttribute('style', "width: 50%; height:0px; margin-top:10px; margin-bottom:10px;");
                    return node;
                }
            }
    
            Hr.blotName = 'hr';
            Hr.tagName = 'hr';
    
            Quill.register({
                'formats/hr': Hr
            }, true);

            const quillEditor = new Quill(`#${id}`, {
                modules: {
                    toolbar: false
                },
                readOnly: true,
                theme: 'snow'
            });
            const deltaContents = new Delta(content);
            quillEditor.setContents(deltaContents as any);
            if (maxChars) {
                quillEditor.deleteText(maxChars, quillEditor.getLength());
            }
        }
    }, [content, id, maxChars])

    const classSelector = ignoreDropCap ? `${styles.content} ${styles.ignoreDropCap}` : styles.content;
    return <div id={id} className={classSelector}></div>;
}

export default ArticleContent;
