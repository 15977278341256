import { Fragment } from "react";
import Calendar from '@mui/icons-material/CalendarToday'

const ArticleDate = ({ date }: { date: string }) => {
    return (
        <Fragment>
            <Calendar fontSize='inherit' />
            <p>{new Date(date).toLocaleDateString(undefined, {
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            })}</p>
        </Fragment>
    )
};

export default ArticleDate;
