import makeRequest from "client/common/request";
import { useEffect, useState } from "react";
import ArticleNavigation from "./articleNavigation";
import styles from './index.module.scss';

const ArticleFooter = ({ createdDate, mainCategory }: { createdDate: string, mainCategory: string }) => {
    const [nextTitle, setNextTitle] = useState('');
    const [nextUri, setNextUri] = useState('');
    const [prevTitle, setPrevTitle] = useState('');
    const [prevUri, setPrevUri] = useState('');

    useEffect(() => {
        makeRequest(`postnav?mainCategory=${mainCategory}&createdDate=${createdDate}`, 'GET')
            .then((res) => {
                const { next, prev } = res;
                setNextTitle(next?.title ?? '');
                setNextUri(next ? `/${next.mainCategory}/${next.secondaryCategory}/${next.id}` : '');
                setPrevTitle(prev?.title ?? '');
                setPrevUri(prev ? `/${prev.mainCategory}/${prev.secondaryCategory}/${prev.id}` : '');
            })
            .catch((error) => {
                console.error(error);
            });
    }, [createdDate, mainCategory])

    return (
        <div className={`${styles.navWrapper} ${prevTitle ? '' : styles.singleNav}`}>
            {prevTitle ? <ArticleNavigation uri={prevUri} direction='left' title={prevTitle} /> : null}
            {nextTitle ? <ArticleNavigation uri={nextUri} direction='right' title={nextTitle} /> : null}
        </div>
    )
};

export default ArticleFooter;
