import AuthState from "client/components/auth/state"
import { API_ENDPOINT } from "./constants"

const makeRequest = async (uri: string, method: 'GET' | 'POST', body?: BodyInit, includeAuth?: boolean): Promise<any> => {
    const headers = AuthState.accessToken && includeAuth ? { 'x-access-token': AuthState.accessToken } : undefined;
    const request = new Request(`${API_ENDPOINT}/${uri}`, { method, headers, body });
    const res = await fetch(request);
    if (!res.ok) {
        throw new Error(`Request failed: ${JSON.stringify(res.json(), null, 2)}`)
    } else {
        return res.json();
    }
};

export default makeRequest;
