import { Link, useParams } from 'react-router-dom';

import styles from './index.module.scss';

const ArticleCategory = ({ category }: { category: string }) => {
    const { mainCategory } = useParams() as Record<string, string>;

    return (
        <Link to={`/${mainCategory}/${category}`} className={styles.category}>{category.replace(/_/g, ' ')}</Link>
    )
};

export default ArticleCategory;
