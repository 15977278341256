export const pageTitle = 'Chelsea Harsch';

export enum mainCategories {
    tech = 'tech',
    personal = 'personal',
    travel = 'travel'
};

export const API_ENDPOINT = 'https://api.chelseaharsch.com';
export const BATCH_GET_PATH = 'batchposts';
export const POST_PATH = 'post';

export const authorMap: Record<string, string> = {
    chelseaharsch: 'Chelsea Harsch'
};

export const landingPage: Record<string, { title: string, image: string, subtitle?: string }> = {
    home: {
        title: 'Welcome',
        image: 'https://ceadventures.life/wp-content/uploads/2020/04/cropped-istock-464852512-copy-1.jpg'
    },
    personal: {
        title: 'Personal',
        image: 'https://ceadventures.life/wp-content/uploads/2020/04/cropped-istock-464852512-copy-1.jpg'
    },
    tech: {
        title: 'Tech',
        image: 'https://ceadventures.life/wp-content/uploads/2020/04/cropped-istock-464852512-copy-1.jpg'
    },
    travel: {
        title: 'Travel',
        image: 'https://ceadventures.life/wp-content/uploads/2020/04/cropped-istock-464852512-copy-1.jpg'
    }
}
