import { Fragment, useEffect, useState } from "react";
import { render } from "react-dom";
import { useParams } from 'react-router-dom';
import HorizontalRule from '@mui/icons-material/HorizontalRule';
import Quill from 'quill';
import Delta from "quill-delta";
import makeRequest from "client/common/request";
import { mainCategories, POST_PATH } from "client/common/constants";

import './index.scss';

enum FOCUS {
    Content,
    Visibility,
    Author,
    Title,
    Password,
    ConfirmPassword,
    MainCategory,
    SecondaryCategory
};

const EditorPage = () => {
    const { articleId } = useParams();
    const [editor, setEditor] = useState(null as Quill | null);
    const [mainCategory, setMainCategory] = useState('');
    const [secondaryCategory, setSecondaryCategory] = useState('');
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('chelseaharsch');
    const [visibility, setVisibility] = useState('Private');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [secondaryCategories, setSecondaryCategories] = useState([] as string[]);
    const [focus, setFocus] = useState(FOCUS.Content);

    useEffect(() => {
        const Embed = Quill.import('blots/block/embed');

        class Hr extends Embed {
            static create(value: any) {
                let node = super.create(value);
                node.setAttribute('style', "width: 50%; height:0px; margin-top:10px; margin-bottom:10px;");
                return node;
            }
        }

        Hr.blotName = 'hr';
        Hr.tagName = 'hr';
        
        Quill.register({
            'formats/hr': Hr
        }, true);
        
        const toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link', 'image'],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['hr'],
            ['clean']
        ];
        
        const quillEditor = new Quill('#editor', {
            modules: {
                toolbar: toolbarOptions
            },
            theme: 'snow'
        });
        
        const toolbar = quillEditor.getModule('toolbar');
        const customHrHandler = function() {
            const range = quillEditor.getSelection();
            if (range) {
                quillEditor.insertEmbed(range.index,"hr","null");
            }
        }
        toolbar.addHandler('hr', customHrHandler);
        render(<HorizontalRule />, document.querySelector('.ql-hr'))

        setEditor(quillEditor);
    }, [])

    useEffect(() => {
        if (articleId && editor) {
            makeRequest(`${POST_PATH}?id=${articleId}&author=chelseaharsch`, 'GET').then((res) => {
                const deltaContents = new Delta(res.Item.content);
                editor.setContents(deltaContents as any);
            });
        }
    }, [articleId, editor])

    useEffect(() => {
        if (mainCategory) {
            makeRequest(`category?mainCategory=${mainCategory}`, 'GET')
                .then((res) => {
                    setSecondaryCategories(res.Items.map((d: { mainCategory: string, secondaryCategory: string}) => d.secondaryCategory));
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    }, [mainCategory])

    useEffect(() => {
        if (focus === FOCUS.Content && editor) {
            editor.focus();
        }
    }, [focus, editor])

    const Password = () => {
        return (
            <Fragment>
                <label>
                    Password:
                    <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type='password'
                        onFocus={() => setFocus(FOCUS.Password)}
                        autoFocus={focus === FOCUS.Password}
                    />
                </label>
                <label>
                    Confirm password:
                    <input
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type='password'
                        onFocus={() => setFocus(FOCUS.ConfirmPassword)}
                        autoFocus={focus === FOCUS.ConfirmPassword}
                    />
                </label>
            </Fragment>
        )
    }

    const Form = () => {
        const handleSubmit = () => {
            const { ops } = editor?.getContents() ?? {};
            if (title && author && mainCategory && secondaryCategory && ops) {
                const body = {
                    title,
                    author,
                    mainCategory,
                    secondaryCategory,
                    content: ops
                };
                makeRequest('post', 'POST', JSON.stringify(body));
            } else {
                console.error('Incomplete form');
            }
        };

        return (
            <form className='new-post-form'>
                <label>
                    Visibility:
                    <select
                        onChange={(e) => setVisibility(e.target.value ?? '')}
                        value={visibility}
                        onFocus={() => setFocus(FOCUS.Visibility)}
                        autoFocus={focus === FOCUS.Visibility}
                    >
                        <option id="private">Private</option>
                        <option id="protected">Protected</option>
                        <option id='public'>Public</option>
                    </select>
                </label>
                {visibility === 'protected' ? <Password /> : null}
                <label>
                    Title:
                    <input
                        type='text'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        onFocus={() => setFocus(FOCUS.Title)}
                        autoFocus={focus === FOCUS.Title}
                    />
                </label>
                <label>
                    Author:
                    <input
                        type='text'
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        onFocus={() => setFocus(FOCUS.Author)}
                        autoFocus={focus === FOCUS.Author}
                    />
                </label>
                <label>
                    Main Category:
                    <select
                        className='capitalize-transform'
                        onChange={(e) => setMainCategory(e.target.value)}
                        value={mainCategory}
                        onFocus={() => setFocus(FOCUS.MainCategory)}
                        autoFocus={focus === FOCUS.MainCategory}
                    >
                        <option key='mainCategory-default' value=''>Select a category</option>
                        {Object.values(mainCategories).map((category) => {
                            return <option key={`mainCategory-${category}`} value={category}>{category}</option>;
                        })}
                    </select>
                </label>
                <label>
                    Secondary Category:
                    <select
                        className='capitalize-transform'
                        onChange={(e) => setSecondaryCategory(e.target.value)}
                        value={secondaryCategory}
                        onFocus={() => setFocus(FOCUS.SecondaryCategory)}
                        autoFocus={focus === FOCUS.SecondaryCategory}
                    >
                         <option key='secondaryCategory-default' value=''>Select a category</option>
                        {secondaryCategories.map((category) => {
                            return <option key={`secondaryCategory-${category}`} value={category}>{category.replace(/_/g, ' ')}</option>;
                        })}
                    </select>
                </label>
                <button type="submit" onClick={handleSubmit}>Publish{visibility ? ` ${visibility}` : ''}</button>
            </form>
        );
    };

    return (
        <Fragment>
            <div className='editor-background' />
            <div className='editor-wrapper'>
                <div>
                    <div id="editor"></div>
                </div>
                <Form />
            </div>
        </Fragment>
    )
}

export default EditorPage;
