import Header from 'client/components/header';
import Router from 'client/router';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import Drawer from './components/drawer';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Drawer />
      <Router />
    </BrowserRouter>
  );
}

export default App;
