import { MutableRefObject, useEffect, useRef } from 'react';

import styles from '../index.module.scss';

const LandingPageTitle = ({ title, subtitle }: { title: string, subtitle?: string }) => {
    return (
        <div className={styles.titleWrapper}>
            <h2>{title}</h2>
            {subtitle ? <p>{subtitle}</p> : null}
        </div>
    )
}

export default LandingPageTitle;
