import { Link } from 'react-router-dom';
import styles from './index.module.scss';

const ArticleNavigation = ({ title, direction, uri }: { title: string, direction: 'left' | 'right', uri: string }) => {
    return (
        <Link to={uri} className={styles.navButton}>
            <div className={direction === 'left' ? '' : styles.displayNone}>&#10094;</div>
            <span>{title}</span>
            <div className={direction === 'right' ? '' : styles.displayNone}>&#10095;</div>
        </Link>
    )
};

export default ArticleNavigation;
