import { Fragment } from "react";
import { Link } from "react-router-dom";

import styles from './index.module.scss';

const ArticleTitle = ({ title, subtitle, linkTo }: { title: string, subtitle?: string, linkTo?: string }) => {
    return (
        <Fragment>
            <h2 className={styles.titleCase}>{linkTo ? <Link to={linkTo}>{title}</Link> : title}</h2>
            {subtitle ? <p>{subtitle}</p> : null}
        </Fragment>
    );
};

export default ArticleTitle;
