import { useState, Fragment, useEffect } from 'react';
import { useNavigate, matchPath } from 'react-router-dom';
import { pageTitle, mainCategories } from 'client/common/constants';
import { useAppDispatch, useAppSelector } from 'client/hooks';
import { fetchCategories, selectCategories, selectCategoriesFailure, selectCategoriesLoading } from 'client/store/categories';

import AppBar from './appBar';
import styles from './index.module.scss';
import List from '../list';

const Header = () => {
    const navigate = useNavigate();
    const { params } = matchPath('/:mainCategory/:secondaryCategory', window.location.pathname) || matchPath('/:mainCategory/:secondaryCategory/:articleId', window.location.pathname) || {};
    const dispatch = useAppDispatch();
    const [previousParams, setPreviousParams] = useState({} as { secondaryCategory?: string, mainCategory?: string, articleId?: string })
    const [headerFixed, setHeaderFixed] = useState(false);
    const [view, setView] = useState('');
    const categoriesLoading = useAppSelector(selectCategoriesLoading);
    const categoriesFailure = useAppSelector(selectCategoriesFailure);
    const categories = useAppSelector(selectCategories);

    const handleScroll = () => {
        const { top } = document.getElementsByTagName('body')[0].getBoundingClientRect() ?? {};

        if (top && top <= -21) {
            setHeaderFixed(true);
        } else if (top || top === 0) {
            setHeaderFixed(false);
        }
    }

    const handleResize = () => {
        const { width } = document.getElementsByTagName('body')[0].getBoundingClientRect() ?? {};
        if (width < 768) {
            setView('mobile');
        } else {
            setView('desktop');
        }
    }

    useEffect(() => {
        if (params?.secondaryCategory === undefined || params?.secondaryCategory === 'home') {
            window.addEventListener('scroll', handleScroll);
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return function() {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (params?.secondaryCategory !== previousParams?.secondaryCategory) {
            setPreviousParams(params ?? {});
            if (params?.secondaryCategory !== undefined && params?.secondaryCategory !== 'home') {
                setHeaderFixed(true);
            } else {
                handleScroll();
            }
        }
    }, [params])

    return (
        <Fragment>
            {view === 'mobile' ? <AppBar /> : null}
            {headerFixed && view === 'desktop' ? <div className={styles.spacer}></div> : null}
            { view === 'desktop' ? <header className={`${styles.siteHeader} ${headerFixed ? styles.fixed : ''}`}>
                <h1>{pageTitle}</h1>
                <List
                    background='transparent'
                    width='auto'
                    items={[
                        {
                            label: 'home',
                            navigate: (e: Event) => navigate('/')
                        },
                        ...Object.values(mainCategories).map((cat) => ({
                            label: cat,
                            subItemsLoading: categoriesLoading[cat],
                            subItemsFailure: categoriesFailure[cat],
                            subItemsBackground: '#00001a',
                            subItemsPosition: 'absolute' as 'absolute',
                            subItemsTop: '48px',
                            subItemsLeft: '0px',
                            subItemsPaddingLeft: 2,
                            fetchSubItems: () => dispatch(fetchCategories(cat)),
                            items: (categories[cat] ?? []).map((catObj) => ({
                                label: catObj.secondaryCategory,
                                navigate: () => navigate(`/${catObj.mainCategory}/${catObj.secondaryCategory}`)
                            }))
                        } as any))
                    ]}
                    row
                    singleExpansion
                    subItemsCollapseOnClick
                />
            </header> : null}
        </Fragment>
    )
};

export default Header;
