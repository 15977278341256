import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { takeEvery } from 'redux-saga/effects'
import appReducer from './app';
import categoriesReducer, { fetchCategories, fetchCategoriesSaga } from './categories';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
      app: appReducer,
      categories: categoriesReducer
  },
  middleware: [sagaMiddleware]
});

sagaMiddleware.run(function* sagas() {
  yield takeEvery(fetchCategories, fetchCategoriesSaga);
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
