import { useCallback } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useAppDispatch, useAppSelector } from 'client/hooks';
import { selectDrawerOpen, setDrawerOpen } from 'client/store/app';
import List from '../list';
import { mainCategories } from 'client/common/constants';
import { selectCategoriesLoading, selectCategoriesFailure, selectCategories, fetchCategories } from 'client/store/categories';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';

const Drawer = () => {
    const navigate = useNavigate();
    const open = useAppSelector(selectDrawerOpen);
    const categoriesLoading = useAppSelector(selectCategoriesLoading);
    const categoriesFailure = useAppSelector(selectCategoriesFailure);
    const categories = useAppSelector(selectCategories);
    const dispatch = useAppDispatch();
    const closeDrawer = useCallback(
        (event: any) => {
            if (!Object.values(mainCategories).some((cat) => (
                Array.from(
                    document.querySelectorAll(`[data-id=${cat.replace(/\s/g, '_').toLowerCase()}]`)
                ).some((el) => el.contains(event.target)))
            )) {
                dispatch(setDrawerOpen(false))
            }
        },
        [dispatch]
    );
    const openDrawer = useCallback(
        () => dispatch(setDrawerOpen(true)),
        [dispatch]
    );

    return (
        <SwipeableDrawer
            anchor='left'
            open={open}
            onClose={closeDrawer}
            onOpen={openDrawer}
        >
            <Box
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                role='div'
            >
                <IconButton aria-label='close' sx={{ width: '42px' }} onClick={closeDrawer}>
                    <KeyboardArrowLeft />
                </IconButton>
            </Box>
            <Box
                sx={{ width: 250 }}
                role="presentation"
            >
                <List items={[
                    {
                        label: 'home',
                        navigate: (e: Event) => {
                            navigate('/');
                            closeDrawer(e);
                        }
                    },
                    ...Object.values(mainCategories).map((cat) => ({
                        label: cat,
                        subItemsLoading: categoriesLoading[cat],
                        subItemsFailure: categoriesFailure[cat],
                        fetchSubItems: () => dispatch(fetchCategories(cat)),
                        items: (categories[cat] ?? []).map((catObj) => ({
                            label: catObj.secondaryCategory,
                            navigate: (e: Event) => {
                                navigate(`/${catObj.mainCategory}/${catObj.secondaryCategory}`);
                                closeDrawer(e);
                            }
                        }))
                    } as any))
                ]} />
            </Box>
        </SwipeableDrawer>
    )
};

export default Drawer;
