class AuthState {
    public static idToken = '';

    public static get accessToken() {
        return window.sessionStorage.getItem('access-token') ?? '';
    }

    public static set accessToken(accessToken: string) {
        window.sessionStorage.setItem('access-token', accessToken);
    }
}

export default AuthState;
