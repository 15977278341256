import { Fragment, useEffect, useState } from 'react';
import Spinner from '@mui/material/CircularProgress';
import { POST_PATH } from 'client/common/constants';

import ArticleHeader from './articleHeader';
import ArticleFooter from './articleFooter';
import ArticleContent from './articleContent';
import makeRequest from 'client/common/request';

const Article = ({
    hideFooter,
    hideHeader,
    articleId,
    article,
    ignoreDropCap,
    maxChars,
    linkHeaderTo
}: {
    articleId?: string,
    author?: string,
    article?: Record<string, any>,
    hideFooter?: boolean,
    hideHeader?: boolean,
    ignoreDropCap?: boolean,
    maxChars?: number,
    linkHeaderTo?: string
}) => {
    const [articleState, setArticle]: any | null = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (articleId) {
            setLoading(true);
            makeRequest(`${POST_PATH}?id=${articleId}&author=chelseaharsch`, 'GET').then((res) => {
                setArticle(res.Item);
                setLoading(false);
            });
        }
    }, [articleId,])

    useEffect(() => {
        if (article) {
            setArticle(article);
        }
    }, [article])

    return (
        <Fragment>
            {loading ? <Spinner /> : null}
            {hideHeader || !articleState || loading ? null : <ArticleHeader article={articleState} linkTo={linkHeaderTo} />}
           {articleState?.content && !loading ? <ArticleContent ignoreDropCap={ignoreDropCap} content={articleState.content} maxChars={maxChars} /> : null}
            {hideFooter || !articleState || loading ? null : <ArticleFooter mainCategory={articleState.mainCategory} createdDate={articleState.createdDate} />}
        </Fragment>
    )
};

export default Article;
