import { authorMap } from "client/common/constants";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import UswerIcon from '@mui/icons-material/Person';

const ArticleAuthor = ({ author }: { author: string }) => {
    return (
        <Fragment>
            <UswerIcon fontSize='inherit' />
            <Link to={`/author/${author}`}>{authorMap[author]}</Link>
        </Fragment>
    )
};

export default ArticleAuthor;
