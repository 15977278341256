import { useEffect, useState } from 'react';
import Spinner from '@mui/material/CircularProgress';
import { BATCH_GET_PATH } from 'client/common/constants';
import Article from 'client/components/article';
import makeRequest from 'client/common/request';

import styles from '../index.module.scss';

const LandingPageContent = ({ category }: { category: string }) => {
    const [article, setArticle] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        makeRequest(`${BATCH_GET_PATH}?mainCategory=landing&secondaryCategory=${category}`, 'GET').then((res) => {
            setArticle(res.Items[0]);
            setLoading(false);
        });
    }, [category])

    return (
        <div className={styles.content}>
            {loading ? <Spinner /> : <Article hideFooter={true} hideHeader={true} article={article} />}
        </div>
    );
}

export default LandingPageContent;
